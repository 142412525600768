import * as React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import {formatDate} from "../../utils/dateFormat";

const BlogCard = ({image, title, excerpt, tag, pubdate, url}) => {

    return (
        <div className="blog-card">
            <Link to={url} className="blog-card__link">
                <Img fixed={image.fixed} alt={image.alt} className="blog-card__image img-fluid" />
                <h2 className="blog-card__title">
                    {title}
                </h2>
                <div className="blog-card__excerpt">
                    <p>
                        {excerpt}
                    </p>
                </div>
                <div className="blog-card__metas">
                    {formatDate(pubdate)} — {tag}
                </div>
            </Link>
        </div>
    )
}

export default BlogCard
