import * as React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/Layout";
import BlogCard from "../components/Blog/BlogCard";
import {RichText} from "prismic-reactjs";
import { Link } from "gatsby";

export const query = graphql`
  query MyPostQuery {
      allPrismicPost(sort: {fields: last_publication_date, order: DESC}) {
          edges {
        node {
          url
          id
          type
          first_publication_date
          last_publication_date
          data {
            featured_image {
                alt
                fixed(width: 648, height: 364) {
                    ...GatsbyPrismicImageFixed
                }
            }
            post_title {
                raw
            }
            excerpt            
            category {
              document {
                ... on PrismicCategory {
                    id
                    data {
                        category_title {
                            raw
                        }
                    }
                }
              }
            }
          }
        }
      }
    }
  }
`

const BlogPage = ({location, path, data}) => {

    if (!data) return null

    const posts = data.allPrismicPost.edges;

    return (
        <Layout location={location} path={path}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <ul className="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>Blog and update</li>
                        </ul>

                        <h1 className="page-title">Blog</h1>
                    </div>
                </div>

                <div className="row g-5">
                    {posts.map((post, index) => {
                        const excerpt = post.node.data.excerpt;
                        const url = post.node.url;
                        const postTitle = RichText.asText(post.node.data.post_title.raw);
                        const featuredImage = post.node.data.featured_image;
                        const firstPublicationDate = post.node.first_publication_date;
                        const category = post.node.data.category.document != null ?
                            RichText.asText(post.node.data.category.document.data.category_title.raw) : '';

                        return (<div className="col-md-6" key={"post-"+index}>
                            <BlogCard url={url} title={postTitle} tag={category} pubdate={firstPublicationDate} excerpt={excerpt} image={featuredImage} />
                        </div>)
                    })}
                </div>
            </div>
        </Layout>
    )
}



export default BlogPage
